/* eslint-disable react-hooks/exhaustive-deps */
/**
 * QuillForms Depndencies
 */
import { useTheme, useMessages } from "@quillforms/renderer-core";

/**
 * React Dependencies
 */
import { useEffect } from "react";

/**
 * External Dependencies
 */
import tinyColor from "tinycolor2";
import { css } from "emotion";
import classnames from "classnames";

const MyCustomBlockDisplay = (props) => {
  const {
    attributes,
    setIsValid,
    setIsAnswered,
    showNextBtn,
    val,
    setVal,
    next,
  } = props;

  const { leftValue, rightValue } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  const items = [
    { label: "😣", value: 1 },
    { label: "🙁", value: 2 },
    { label: "😐", value: 3 },
    { label: "🙂", value: 4 },
    { label: "😀", value: 5 },
  ];

  useEffect(() => {
    //checkfieldValidation(val);
    console.log(val);
    if (val && val !== "") {
      setIsValid(true);
      setIsAnswered(true);
      let timer = setTimeout(() => {
        next();
      }, 500);
    } else {
      setIsAnswered(false);
      showNextBtn(false);
    }
  }, [val]);

  return (
    <>
      <div
        className={css`
          display: flex;
          flex-wrap: no-wrap;
          width: 100%;
          height: 62px;
          margin-top: 15px;
        `}
      >
        {items.map((item) => {
          return (
            <div
              key={item.label}
              className={classnames(
                css`
                  height: 100%;
                  @media (min-width: 768px) {
                    flex: 1 1 0%;
                  }
                  @media (max-width: 767px) {
                    min-width: 40px;
                    flex-basis: calc(${100 / items.length}% - 6px);
                  }
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  outline: none;
                  font-size: 28px;
                  cursor: pointer;
                  user-select: none;
                  margin-right: 6px;
                  margin-bottom: 10px;
                  border-radius: 4px;
                  background-color: ${answersColor.setAlpha(0.1).toString()};
                  color: ${answersColor.setAlpha(1).toString()};
                  box-shadow: ${answersColor.setAlpha(0.6).toString()} 0px 0px
                    0px 1px inset;
                  position: relative;
                  transition: all 0.1s ease-out 0s;
                  &:hover {
                    background: ${answersColor.setAlpha(0.2).toString()};
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  &.selected {
                    background: ${tinyColor(theme.answersColor)
                      .setAlpha(0.75)
                      .toString()};
                    color: ${tinyColor(theme.answersColor).isDark()
                      ? "#fff"
                      : "#333"};
                  }
                `,
                { selected: val === item.value }
              )}
              onClick={() => {
                setVal(item.value);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div
        className={css`
          display: flex;
          width: 100%;
          justify-content: space-between;
        `}
      >
        <p
          className={css`
            color: ${answersColor.setAlpha(1).toString()};
            font-size: 14px;
          `}
        >
          {leftValue}
        </p>
        <p
          className={css`
            color: ${answersColor.setAlpha(1).toString()};
            font-size: 14px;
          `}
        >
          {rightValue}
        </p>
      </div>
    </>
  );
};
export default MyCustomBlockDisplay;
