import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./mood";
import "./styles.css";
import { createClient } from "@supabase/supabase-js";
const queryParameters = new URLSearchParams(window.location.search);
const patient_id = +queryParameters.get("patient_id");
// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://lvykkopdvnxbqupaivhe.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2eWtrb3Bkdm54YnF1cGFpdmhlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg5ODMzMjIsImV4cCI6MjAyNDU1OTMyMn0.7Y7CC_FwB58ETbhA6jDszC70oxTm0O10ANg8O8vge4o"
);
registerCoreBlocks();

const App = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          messages: {
            "label.button.ok": "Suivant", // Default: 'Ok'
            "label.hintText.enter": "appuyez sur <strong>Entrer ↵</strong>", // Default: "",
            "label.hintText.multipleSelection":
              "Choisissez autant que nécessaire", // Default: "Choose as many as you like"
            "block.dropdown.placeholder": "Sélectionnez une option", // Default: "Type or select an option
            "block.dropdown.noSuggestions": "Aucune suggestion", // Default: "No Suggestions!"
            "block.shortText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.hint":
              "<strong>Shift ⇧ + Entrer ↵</strong> pour créer un saut de ligne", // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
            "block.number.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.email.placeholder": "Votre e-mail", // Default: "Type your email here"
            "block.defaultThankYouScreen.label":
              "Merci, Votre réponse a été enregistrée.", // Default: "Thanks for filling this in.\n\n We will contact you soon"
            "label.hintText.key": "Touche", // Default: "Key"
            "label.progress.percent":
              "Questionnaire quotidien {{progress:percent}}% complet", // Default: "Questionnaire quotidien {{progress:percent}}% complet"
            "label.errorAlert.required": "Champs obligatoire", // Default: "This field is required!"
            "label.errorAlert.date": "Date invalide", // Default: "Invalid date!"
            "label.errorAlert.number": "Nombres uniquement", // Default: "Numbers only!"
            "label.errorAlert.selectionRequired":
              "Sélectionnez au moins une option", // Default: "Please make at least one selection!"
            "label.errorAlert.email": "E-mail invalide", // Default: "Invalid email!"
            "label.errorAlert.url": "URL invalide", // Default: "Invalid url!"
            "label.errorAlert.range":
              "Merci de choisir un nombre entre {{attribute:min}} et {{attribute:max}}", // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
            "label.errorAlert.minNum":
              "Merci d'entrer un nombre supérieur à {{attribute:min}}", // Default: "Please enter a number greater than {{attribute:min}}"
            "label.errorAlert.maxNum":
              "Merci d'entrer un nombre inférieur à {{attribute:max}}", // Default: "Please enter a number lower than {{attribute:max}}"
            "label.errorAlert.maxCharacters":
              "Nombre maximum de caractères atteint", // Default: "Maximum characters reached!"
            "label.submitBtn": "Envoyer mes réponses", // Default: "Submit"
          },
          blocks: [
            {
              name: "dropdown",
              id: "alcohol_units",
              attributes: {
                label:
                  "Combien d'unités d'alcool avez-vous consommé dans les dernières 24h ?",
                choices: [
                  { value: 0, label: "0" },
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                  { value: 3, label: "3" },
                  { value: 4, label: "4" },
                  { value: 5, label: "5" },
                  { value: 6, label: "6" },
                  { value: 7, label: "7" },
                  { value: 8, label: "8 ou +" },
                ],
              },
            },
            {
              name: "dropdown",
              id: "number_of_cravings",
              attributes: {
                label:
                  "Combien avez-vous eu de cravings dans les 24 dernières heures ?",
                choices: [
                  { value: 0, label: "0" },
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                  { value: 3, label: "3" },
                  { value: 4, label: "4" },
                  { value: 5, label: "5 ou +" },
                ],
              },
            },
            {
              name: "mood",
              id: "mood",
              attributes: {
                label: "Quelle est votre humeur aujourd'hui ?",
                leftValue: "Mauvaise",
                rightValue: "Bonne",
              },
            },
            {
              name: "mood",
              id: "anxiety",
              attributes: {
                label: "Quel est votre niveau de sérénité aujourd'hui ?",
                leftValue: "Bas",
                rightValue: "Haut",
              },
            },

            {
              name: "mood",
              id: "sleep_quality",
              attributes: {
                label: "Comment avez-vous dormi cette nuit ?",
                leftValue: "Mal",
                rightValue: "Bien",
              },
            },
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          setTimeout(async () => {
            console.log(data.answers);
            await supabase.from("daily_measurements").insert({
              patient_id,
              alcohol_units: +data.answers.alcohol_units.value,
              number_of_cravings: +data.answers.number_of_cravings.value,
              mood: +data.answers.mood.value - 1,
              anxiety: +data.answers.anxiety.value - 1,
              sleep_quality: +data.answers.sleep_quality.value - 1,
            });
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </div>
  );
};
export default App;
