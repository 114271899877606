import { registerBlockType } from "@quillforms/blocks";
import display from "./display";

registerBlockType("mood", {
  supports: {
    editable: true,
  },
  attributes: {
    leftValue: {
      type: "string",
      default: "",
    },
    rightValue: {
      type: "string",
      default: "",
    },
  },
  display,
});
